import { Routes } from '@angular/router';
import { loggedInGuard } from '../../core/guards/logged-in.guard';
import { authGuard } from '../../core/guards/auth.guard';

export const ayookinsRoutes: Routes = [{
    path: '',
    canActivate: [loggedInGuard],
    loadComponent: () => import('./ayookins-landing/ayookins-landing.component').then((m) => m.AyookinsLandingComponent)
}, {
    path: 'onboarding',
    canActivate: [authGuard],
    loadComponent: () => import('./dashboard/components/onboard/onboard.component').then((m) => m.OnboardComponent)
}, {
    path: 'dashboard',
    canActivate: [authGuard],
    loadComponent: () => import('./dashboard/dashboard.component').then((m) => m.DashboardComponent)
},
{
    path: 'profile/:id',
    canActivate: [authGuard],
    loadComponent: () => import('./dashboard/components/profile/profile.component').then((m) => m.ProfilepageComponent)
},
{
    path: 'edit-profile/:id',
    canActivate: [authGuard],
    loadComponent: () => import('./dashboard/components/profile/components/edit-profile/edit-profile.component').then((m) => m.EditprofileComponent)
},
{
    path: 'quest-update/:id',
    canActivate: [authGuard],
    loadComponent: () => import('./dashboard/components/quest-update/quest-update.component').then((m) => m.QuestUpdateComponent)
},
{
    path: 'quest-update/:type/:id',
    canActivate: [authGuard],
    loadComponent: () => import('./dashboard/components/quest-update/quest-update.component').then((m) => m.QuestUpdateComponent)
},
{
    path: 'challenge/:id',
    loadComponent: () => import('./dashboard/components/quests/quests.component').then((m) => m.QuestsComponent)
},
{
    path: 'coupons',
    loadComponent: () => import('./dashboard/components/coupons/coupons.component').then((m) => m.CouponsComponent),
},
{
    path: 'recommendations',
    loadComponent: () => import('./dashboard/components/quest-update/components/recommendations/recommendations.component').then((m) => m.RecommendationsComponent),
},
{
    path: 'stats-graphs/:userId/:statId',
    loadComponent: () => import('./dashboard/components/profile/components/stats-graphs/stats-graphs.component').then((m) => m.StatsGraphsComponent),
},
{
    path: 'account-settings/:id',
    loadComponent: () => import('./dashboard/components/menu/account-settings/account-settings.component').then((m) => m.AccountSettingsComponent),
},
{
    path: 'family',
    loadComponent: () => import('./dashboard/components/family/family.component').then((m) => m.FamilyComponent),
},
{
    path: 'feeds',
    loadComponent: () => import('./dashboard/components/feeds/feeds.component').then((m) => m.FeedsComponent),
},
{
    path: 'arcade/:id',
    loadComponent: () => import('./dashboard/components/arcade/components/arcade-component/arcade.component').then((m) => m.ArcadeArcadeComponent),
},
{
    path: 'arcade-dashboard/:id',
    loadComponent: () => import('./dashboard/components/arcade/components/arcade-dashboard/arcade-dashboard/arcade-dashboard.component').then((m) => m.ArcadeDashboardComponent),
},
{
    path: 'arcade-leaderboard/:id',
    loadComponent: () => import('./dashboard/components/arcade/components/arcade-dashboard/arcade-leaderboard/arcade-leaderboard.component').then((m) => m.ArcadeLeaderboardComponent),
},
{
    path: 'mission/:id',
    loadComponent: () => import('./dashboard/components/arcade/components/mission-view/mission-view.component').then((m) => m.MissionViewComponent),
},
{
    path: 'game-selection',
    loadComponent: () => import('./dashboard/components/arcade/components/arcade-games/game-selection/game-selection.component').then((m) => m.GameSelectionComponent),
},
{
    path: 'game-dashboard/:id',
    loadComponent: () => import('./dashboard/components/arcade/components/arcade-games/game-dashboard/game-dashboard.component').then((m) => m.GameDashboardComponent),
},
{
    path: 'life-game',
    loadComponent: () => import('./dashboard/components/arcade/components/arcade-games/life-game/life-game.component').then((m) => m.LifeGameComponent),
},
{
    path: 'time-game',
    loadComponent: () => import('./dashboard/components/arcade/components/arcade-games/time-game/time-game.component').then((m) => m.TimeGameComponent),
},
{
    path: 'leaderboard/:id',
    loadComponent: () => import('./dashboard/components/arcade/components/arcade-games/game-leaderboard/game-leaderboard.component').then((m) => m.GameLeaderboardComponent),
}
]
