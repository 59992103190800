import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { GameService } from '../services/game.service';


export const loggedInGuard: CanActivateFn = () => {
    const authService = inject(AuthenticationService)
    const gameService = inject(GameService)
    const router = inject(Router)
    const currentUser = authService.currentUser.value
    if (currentUser?._id) {
        gameService.checkOnboard().subscribe((res) => {
            if (res.data.status) {
                router.navigate(['/dashboard']);
                return;
            }
            router.navigate(['/onboarding']);
        });
        // router.navigate(['/onboarding'])
        return false
    } else {
        return true
    }
}
